import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActionSheetController, AlertController, ModalController, LoadingController } from '@ionic/angular';
import { AuthService } from './auth.service';
import { BehaviorSubject } from 'rxjs';
import { Observable, Subject, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import Toastr from 'toastr2';
import * as Rx from "rxjs";

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable({
  providedIn: 'root'
})
export class CommonService implements OnDestroy {

  private shareMessage = new BehaviorSubject("Default Message");
  // apiUrl: any = 'http://localhost:8182/';

  // apiUrl: any = 'https://api.dev.idmx.io/';
  apiUrl: any = 'https://api.qa.idmx.io/';
  chatBotUrl: any = 'https://chatbot.idmx.io/';
  qAAPIUrl: any = 'https://api.qa.idmx.io/';
  // apiUrl: any = 'http://192.168.0.105:8182/';
  userId: any;
  organisationDetails: any = {};
  userDetails: any = {};
  loading: any;
  request = new Rx.BehaviorSubject({});
  variant = new Rx.BehaviorSubject({});
  deleteVariant1 = new Rx.BehaviorSubject({});
  selectedVariant = new Rx.BehaviorSubject({});
  // form:any=[];
  // formData:any={};
  // objectName:any;
  // multiLineFormData:any={}
  // notDraftCreate:any;
  // selectedVariantRequestNo:any;

  currentTable = new Subject();
  currentDBTable = new Subject();
  currentHanaDBTable = new Subject();
  fetchRequests: any = [];
  setTable: string;
  setDBTable: string;
  dataName: any = [];
  val: any = [];
  filePath: any = []
  databaseKeys: any = [];
  massInfoUrl: any = {
    Material: {
      url: 'massMessageReadMaterial',
      fields: ['MaterialNumber', 'Msgtype']
    },
    Customer: {
      url: 'massMessageReadCustomer',
      fields: ['Businesspartner', 'Customer']
    },
    Vendor: {
      url: 'massMessageReadVendor',
      fields: ['Businesspartner', 'Vendor']
    },
    Routing: {
      url: 'massMessageReadRouting',
      fields: ['Material', 'Plant', 'Type']
    },
    CostCenter: {
      url: 'massMessageReadCostCenter',
      fields: ['Kostl', 'Requestnumber']
    },
    GL: {
      url: 'massMessageReadGL',
      fields: ['Saknr', 'Typecs']
    },
    BOM: {
      url: 'massMessageReadBOM',
      fields: ['Matnr', 'Werks', 'MsgType']
    },
    ProfitCenter: {
      url: 'massMessageReadProfitCenter',
      fields: ['Profitctr', 'Typecs']
    },
    Article: {
      url: 'massMessageReadArticle',
      fields: ['MatnrX', 'Type']
    }
  }
  organisationId: any;
  error: any;
  BASE_URL: any;
  fetchSupplier: any = [];
  fetchMasterData: any = [];
  wForm: any = '';
  subscriptionUserDeatils: any;

  private _successMsgSource = new Subject<boolean>();
  successMsg$ = this._successMsgSource.asObservable();

  private _successMsgSource2 = new Subject<boolean>();
  successMsg$2 = this._successMsgSource2.asObservable();

  constructor(
    public http: HttpClient,
    public loadingController: LoadingController,
    public authService: AuthService,
    public alertController: AlertController,
    public actionSheetController: ActionSheetController,
    public modalCtrl: ModalController,
    public router: Router
  ) {
//     let that = this;
//     that.request.subscribe((resp: any) => {
    
//       let formValid = true;
//       if (resp && resp.model) {
// debugger
//         // console.log("request resp ", resp)
//         let createObject:any = Object.assign(this.formData, this.multiLineFormData);
//         that.request.next({})

      
//         // console.log("this.formRequiredFields ", this.formRequiredFields)

//         // for (let field of this.formRequiredFields) {
//         //   if (this.formData[field] == '' || !this.formData[field]) {
//         //     formValid = false;
//         //     this.showToast('error', 'Please fill all required fields.');
//         //     break;
//         //   }
//         // }


//         for (let tab of this.form) {
//           // console.log("tab.type ", tab.type)

//           if (tab.type == 'standardGroup' && formValid == true) {
//             if (tab.segments) {
//               for (let segment of tab.segments) {
//                 if (segment.tabData) {

//                   for (let field of segment.tabData) {
//                     //Added by Parul for checking dates should not in past

//                     if (field.mindate) {
//                       if (field.mindate == 'current' && createObject[field.model] < new Date().toISOString().slice(0, 10)) {
//                         formValid = false;

//                         // if (resp.saveAsDraft == false) {
//                         this.showToast('error', field.label + ' in ' + tab.label + ' must be within usability period');
//                         break;
//                         // }

//                       }
//                     }
//                   }
//                 }
//               }
//             }

//           }
//           if (tab.type == 'standard') {
//             if (tab.tabData) {

//               for (let field of tab.tabData) {
//                 // console.log("createObject[field] ", createObject[field.model])

//                 ///Added by parul for changing text to upper case
//                 if (field.case) {
//                   if (field.case == 'Uppercase') {
//                     createObject[field.model] = createObject[field.model].toUpperCase();
//                   }
//                   // console.log("createObject[field.model]", createObject[field.model])
//                 }

//                 ///Added by parul for checking email validation
//                 if (field.fieldValidation && this.notDraftCreate==true) {
//                   if (field.fieldValidation == 'email') {
//                     if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(createObject[field.model]))) {
//                       formValid = false;

//                       // if (resp.saveAsDraft == false) {
//                       this.showToast('error', 'Please enter Valid - ' + field.label);
//                       break;
//                       // }

//                     }
//                   }
//                 }

//                 //Added by Parul for checking dates should not in past

//                 if (field.mindate) {
//                   if (field.mindate == 'current' && createObject[field.model] < new Date().toISOString().slice(0, 10)) {
//                     formValid = false;
//                     this.showToast('error', field.label + ' in ' + tab.label + ' must be within usability period');
//                     break;
//                   }
//                 }

//                 ///To check mandatory field validation
//                 if(this.notDraftCreate==true){
//                   if (field.required && (createObject[field.model] == '' || !createObject[field.model]) && formValid == true) {
//                     // console.log("field.required ", field.required)
//                     formValid = false;
//                     // if (resp.saveAsDraft == false) {
//                     this.showToast('error', 'Please fill all required fields - ' + field.label + ' is missing!');
//                     break;
//                     // } 
//                     // else {
//                     //   formValid = true;
//                     // }
//                   }
//                 }
//               }

//             }
//           }

//           if(this.notDraftCreate==true){
//             if (tab.type == 'multiline' && formValid == true) {
//               // console.log("tab data", tab)
  
//               if (tab.tabDataStandard) {
  
//                 for (let field of tab.tabDataStandard) {
//                   // console.log("createObject[field] ", createObject[field.model])
//                   if (field.required && (createObject[field.model] == '' || !createObject[field.model])) {
//                     // console.log("field.required ", field.required)
//                     formValid = false;
//                     // if (resp.saveAsDraft == false) {
//                     this.showToast('error', 'Please fill all required fields - ' + field.label + ' is missing!');
//                     break;
//                     // }
//                   }
//                 }
  
//               }
//               ///added by Parul for multiline required field
  
//               if (tab.tabData) {
//                 // console.log("tabData", tab)
  
//                 for (let field of tab.tabData) {
//                   // console.log("createobject", createObject[tab.model])
//                   if (field.required && (createObject[tab.model][0][field.model] == '' || !createObject[tab.model][0][field.model]) && formValid == true) {
//                     // console.log("createObject[field] ", createObject[field.model])
//                     // console.log("field.required ", field.required)
//                     formValid = false;
//                     // if (resp.saveAsDraft == false) {
//                     this.showToast('error', 'Please fill all required fields - ' + field.label + ' is missing!');
//                     break;
//                     // }
//                   }
//                 }
  
//               }
  
//             }
//           }
//         }

//         if (formValid) {

//           this.presentLoading('Please wait...');
//           let createObject = Object.assign(this.formData, this.multiLineFormData, { model: resp.model, unique: resp.unique });

//           if (resp.status)
//             createObject['status'] = resp.status;

//           if (resp._id)
//             createObject['_id'] = resp._id;

//           if (this.selectedVariantRequestNo)
//             createObject['requestNo'] = this.selectedVariantRequestNo;

//           if (resp.fromSupplierPortal)
//             createObject['fromSupplierPortal'] = true;

//           // console.log("createObject ", createObject);

//           if (resp.callURL) {
//             this.loading.dismiss();
//             let model = resp.model
//             // get model data from api and fill
//             this.callExternalAPI(resp.callURL, createObject).then(resp => {
//               this.request.next({});
//               if(model == "materialExtension")
//               {
//               this.showToast('success', "Material Extended")
//               }
//               else{
//                 this.showToast('success', "New " + this.objectName + " Created..")
//               }
//               this.loading.dismiss();
//               // this.navController.pop();
//             }, error => {
//               // console.log(error);
//               this.showToast('error', error.error.message)
//             })
//           } else {
//             this.createRequest(createObject).then((resp: any) => {
//               this.request.next({});
//               this.showToast('success', "New Request " + resp.result.requestNo + " for " + this.objectName + " Created..")
//               // console.log("objectName", this.objectName)
//               this.loading.dismiss();
//               // this.navController.pop();
//             }, error => {
//               // console.log(error);
//               this.showToast('error', error.error.message)
//             })
//           }
//         }
//         //  this.subscriptionRequest.unsubscribe();

//       }
     
//       // this.subscriptionRequest.unsubscribe();
//     })



  }
  import(payload) {
    return this.http.get(this.authService.apiUrl + payload, { responseType: 'blob' })
  }

  validateForm(message: any) {
    this._successMsgSource.next(message);
  }

  requestSubscribestion() {
    return new Promise((resolve, reject) => {
      this.request.subscribe(res => {
        resolve(res)
      })
    })
  }

  validateForm2(message: any) {
    this._successMsgSource2.next(message);
  }
  downloadTemplate(domain) {
    return new Promise((resolve, reject) => {
      this.http.get(this.authService.apiUrl + 'downloadMassTemplate?domain=' + domain).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  massUpload(payload: any, data: any) {
    data.append('organisationId', this.authService.organisationId);
    data['organisationId'] = this.authService.organisationId;
    console.log("data upload", data)
    return this.http.post(this.authService.apiUrl + 'uploadExcel', data)
  }
  uploadPurchaseOrder(payload: any, data: any) {
    data.append('organisationId', this.authService.organisationId);
    data['organisationId'] = this.authService.organisationId;
    console.log("data upload", data)
    return this.http.post(this.authService.apiUrl + 'getEDIData', data)
  }
  massUploadSales(payload: any, data: any) {
    if (this.authService.userType == 'organisation') {
      data.append('organisationId', this.authService.organisationId);
      data['organisationId'] = this.authService.organisationId;
      data.append('userId', this.authService.organisationId);
      data['userId'] = this.authService.organisationId;
      data.append('userName', this.authService.organisationName);
      data['userName'] = this.authService.organisationName;
    }
    else {
      data.append('organisationId', this.authService.organisationId);
      data['organisationId'] = this.authService.organisationId;
      data.append('userId', this.authService.userId);
      data['userId'] = this.authService.userId;
      data.append('userName', this.authService.userName);
      data['userName'] = this.authService.userName;
    }
    console.log("data upload", data)
    return this.http.post(this.authService.apiUrl + 'massUploadSalesOrder', data)
  }

  getDropdownValues(objectName, source, params) {
    if (source == "postalCode ") {
      objectName = "Vendor"
    } else if (source == "country" || source == "region" || source=='partnerFunctions') {
      objectName = "Customer"
    }
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'dropdownValues', {
        modelName: objectName,
        source: source,
        params: params,
        organisationId: this.authService.organisationId
      }).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  updateRequestStatus(domain, id, data) {
    return new Promise((resolve, reject) => {
      this.userId = JSON.parse(localStorage.getItem('userId'))
      this.http.post(this.authService.apiUrl + 'approveRejectReq', { id: [id], domain, status: data.status, comment: data.comment, organisationId: this.authService.organisationId, userId: this.userId }).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  validateAddress2(address) {
    return new Promise((resolve, reject) => {
      this.userId = JSON.parse(localStorage.getItem('userId'))
      this.http.post(this.authService.apiUrl + 'addressValidate1', { address: address }).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  validateAddress(address) {
    return new Promise((resolve, reject) => {
      this.userId = JSON.parse(localStorage.getItem('userId'))
      this.http.post(this.authService.apiUrl + 'addressValidate2', { address: address }).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  sendToSap(domain, requestNumber) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'sendToSAP', { domain: domain, id: requestNumber, organisationId: this.authService.organisationId, userId: this.authService.userId }).subscribe((resp: any) => {
        if (resp.message)
          this.showToast('error', resp.message.value);
        resolve(resp)
      }, error => {
        if (error.message)
          this.showToast('error', error.error.text);
        reject(error)
      })
    })
  }

  fetchPIMAuditLog(domain, requestNumber) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'getTrackStatus', { domain: domain, _id: requestNumber, organisationId: this.authService.organisationId }).subscribe((resp: any) => {
        if (resp.message)
          this.showToast('error', resp.message.value);
        resolve(resp)
      }, error => {
        if (error.message)
          this.showToast('error', error.error);
        reject(error)
      })
    })
  }

  sendToArticlemaster(domain, requestNumber,varientNo) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'sendToMasterData', { domain: domain, id: requestNumber, variantId: varientNo , organisationId: this.authService.organisationId }).subscribe((resp: any) => {
        if (resp.message)
          this.showToast('error', resp.message.value);
        resolve(resp)
      }, error => {
        if (error.message)
          this.showToast('error', error.error);
        reject(error)
      })
    })
  }

  createRequest(request) {
    this.userId = JSON.parse(localStorage.getItem('userId'))
    let requestObject = Object.assign(request, { userId: this.userId })
    return new Promise((resolve, reject) => {

      this.subscriptionUserDeatils = this.authService.userDetails.subscribe((userDetails: any) => {
        console.log("userDetails ", userDetails)
        if (userDetails.organisationType == 'organisation')
          requestObject.organisationId = userDetails._id;
        else
          requestObject.organisationId = userDetails.organisationId;
        let form_data: FormData = this.jsonToFormData(requestObject);
        this.http.post(this.authService.apiUrl + 'createRequest', form_data).subscribe(resp => {
          resolve(resp)
        }, error => {
          reject(error)
        })
      })
    })
  }


  callExternalAPI(source, request) {
    this.userId = JSON.parse(localStorage.getItem('userId'))
    let requestObject = Object.assign(request, { userId: this.userId, organisationId: this.authService.organisationId })
    console.log("request ", requestObject)
    return new Promise((resolve, reject) => {
      let form_data: FormData = this.jsonToFormData(requestObject);
      this.http.post(this.authService.apiUrl + source, form_data).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  jsonToFormData(data) {
    const formData = new FormData();
    this.buildFormData(formData, data, '');
    return formData;
  }

  buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {
      const value = data == null ? '' : data;

      formData[parentKey] = value;
      formData.append(parentKey, value);
    }
  }

  ////////////////Article Heirachy

  rangeArticleList(domain) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.url + 'articleSetByPrepackRange', domain).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  equalDate(domain) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.url + 'articleSetByDateEqual', domain).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  betweenDate(domain) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.url + 'articleSetByDateRange', domain).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  dateAndArticle(domain) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.url + 'articleSetByArtInDateRange', domain).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  beforeDate(domain) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.url + 'articleSetByDateLess', domain).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  afterDate(domain) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.url + 'articleSetByDateGreater', domain).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  articleHerarchyMultiple(domain) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.url + 'articleHerarchyMultiple', domain).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  createArticleHierarchy(domain) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.url + 'CreateArticleHierarchyRequest', domain).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  validateArticleNumber(domain) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.url + 'hierarchyNodeValidation', domain).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  validtateArticleList(domain) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.url + 'validtateArticleList', domain).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  fetchArticleHierarchyRequest(domain) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.url + 'fetchArticleHierarchyRequest', domain).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  changePassword(domain) {
    domain['organisationId'] = this.authService.organisationId;
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'registerUser', domain).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  ////////////////////
  async saveVariant(model, request) {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: 'Enter Variant Name!',
        inputs: [
          {
            name: 'variantName',
            type: 'text',
            placeholder: 'Variant Name'
          }
        ],
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              console.log('Confirm Cancel');
            }
          }, {
            text: 'Save',
            handler: (data) => {
              console.log('Confirm Ok', data);
              this.userId = JSON.parse(localStorage.getItem('userId'))
              let requestObject = Object.assign({ variant: request }, { userId: this.userId, domain: model, variantName: data.variantName, organisationId: JSON.parse(localStorage.getItem("userId")) })
              console.log(requestObject)
              this.http.post(this.authService.apiUrl + 'createVariant', requestObject).subscribe((resp: any) => {
                if (resp.msg) {
                  this.showToast('error', resp.msg)
                } else {
                  this.showToast('success', "Varient save Successfully")
                }
                resolve(resp)
              }, error => {
                reject(error)
              })
            }
          }
        ]
      });

      await alert.present();

    })
  }

  async fetchAllrequest(params) {

    this.userId = JSON.parse(localStorage.getItem('userId'))
    let paramsObject = Object.assign(params, { userId: this.userId })
    return new Promise(async (resolve, reject) => {

      // this.authService.userDetails.subscribe( (userDetails:any) => {
      // console.log("userDetails ", userDetails)
      // if(userDetails.organisationType == 'organisation')
      // paramsObject.organisationId = userDetails._id;
      // else
      console.log("this.authService.organisationId ", this.authService.organisationId)
      paramsObject.organisationId = this.authService.organisationId;
      // console.log("paramsObject ", paramsObject)
      this.http.post(this.authService.apiUrl + 'fetchAllRequest', paramsObject).subscribe(resp => {
        this.fetchRequests = resp
        resolve(resp)
      }, error => {
        reject(error)
      })
      // })


    })
  }

  // async fetchPurchaseOrder(params) {

  //   this.userId = JSON.parse(localStorage.getItem('userId'))
  //   let paramsObject = Object.assign(params, { userId: this.userId })
  //   return new Promise(async (resolve, reject) => {

     
  //     console.log("this.authService.organisationId ", this.authService.organisationId)
  //     paramsObject.organisationId = this.authService.organisationId;
  //     // console.log("paramsObject ", paramsObject)
  //     this.http.post(this.authService.apiUrl + 'fetchEdiPOData', paramsObject).subscribe(resp => {
  //       this.fetchRequests = resp
  //       resolve(resp)
  //     }, error => {
  //       reject(error)
  //     })
  //     // })


  //   })
  // }

  async fetchAllSalesOrders(params) {

    this.userId = JSON.parse(localStorage.getItem('userId'))
    let paramsObject = Object.assign(params, { userId: this.userId })
    return new Promise(async (resolve, reject) => {
      console.log("this.authService.organisationId ", this.authService.organisationId)
      paramsObject.organisationId = this.authService.organisationId;
      // console.log("paramsObject ", paramsObject)
      this.http.post(this.authService.apiUrl + 'fetchAllSalesOrders', paramsObject).subscribe(resp => {
        this.fetchRequests = resp
        resolve(resp)
      }, error => {
        reject(error)
      })
      // })


    })
  }

  async fetchInvoiceData() {
    return new Promise(async (resolve, reject) => {
      this.http.get(this.authService.apiUrl + 'fetchHeaderData').subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      }
      )
    })
  }
  async fetchBillingData() {
    return new Promise(async (resolve, reject) => {
      this.http.get(this.authService.apiUrl + 'fetchBillingData').subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      }
      )
    })
  }
  async fetchMaterialNumData(data) {
    return new Promise(async (resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'fetchMaterial',data).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      }
      )
    })
  }
  async fetchCustomerNumData(data) {
    return new Promise(async (resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'fetchCustomer',data).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      }
      )
    })
  }
  async createInclusion(data) {
    return new Promise(async (resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'saveListDataUpdate',data).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      }
      )
    })
  }
  async fetchAllMaterialNumData() {
    return new Promise(async (resolve, reject) => {
      this.http.get(this.authService.apiUrl + 'fetchMaterialNumber').subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      }
      )
    })
  }
  async fetchAllCustomer() {
    return new Promise(async (resolve, reject) => {
      this.http.get(this.authService.apiUrl + 'fetchCustomerNumber').subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      }
      )
    })
  }
  async fetchDatabySalesOrder(data) {
    return new Promise(async (resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'fetchCustomerNew', data).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      }
      )
    })
  }
  async updateupcNo(data) {
    return new Promise(async (resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'BillingUpdate', data).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      }
      )
    })
  }
  aiValidate() {
    this.userId = JSON.parse(localStorage.getItem('userId'))
    let paramsObject = { userId: this.userId, organisationId: this.authService.organisationId };
    return this.http.post(this.qAAPIUrl + 'aiValidate', paramsObject);
  }

  createValidated() {
    this.userId = JSON.parse(localStorage.getItem('userId'))
    let paramsObject = { userId: this.userId, organisationId: this.authService.organisationId };
    // return this.http.post(this.apiUrl + 'createSalesOrders', paramsObject);
    return this.http.post(this.qAAPIUrl + 'createSalesOrders', paramsObject);
  }



  async fetchAllAuditLog(data) {

    return new Promise(async (resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'fetchModuleLogs', data).subscribe(resp => {
        // this.fetchRequests = resp
        resolve(resp)
      }, error => {
        reject(error)
      })
    })

  }

  async fetchAuditLog(data) {

    return new Promise(async (resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'fetchRequestLogs', data).subscribe(resp => {
        // this.fetchRequests = resp
        resolve(resp)
      }, error => {
        reject(error)
      })
    })

  }

  async fetchAllPIMAuditLog(data) {

    return new Promise(async (resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'getTrackStatus',  data).subscribe(resp => {
        // this.fetchRequests = resp
        resolve(resp)
      }, error => {
        reject(error)
      })
    })

  }
  
  async fetchAutoFixed(data) {

    return new Promise(async (resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'aiAutoFixMaterial', data).subscribe(resp => {
        // this.fetchRequests = resp
        resolve(resp)
      }, error => {
        reject(error)
      })
    })

  }

  fetchData(data) {
    return this.http.post(this.apiUrl + 'fetchfusionwiserequest', data);
  }
  fetchBothData(data) {
    return this.http.post(this.apiUrl + 'fetchdatawithduplicates', data);
  }
  fetchSupplierRequest(data) {
    return this.http.post(this.apiUrl + 'fetchnewrequest', data);
  }
  createConsolidate(data) {
    return this.http.post(this.apiUrl + 'createnewfusionwise', data);
  }
  //Added by parul for supplier dashboard
  async fetchSupplierRequests() {

    this.userId = JSON.parse(localStorage.getItem('userId'))
    return new Promise(async (resolve, reject) => {

      this.http.post(this.authService.apiUrl + 'fetchSupplierRequests', { userId: this.userId, orgId: this.authService.organisationId }).subscribe(resp => {
        this.fetchSupplier = resp;
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  showToast(action, message) {
    var toastr = new Toastr();
    // toastr.options.closeMethod = 'fadeOut';
    toastr.options.closeDuration = 300;
    toastr.options.progressBar = true;
    toastr.options.positionClass = "bottom right,";
    // toastr.options.closeEasing = 'swing';
    // if(action == 'success')
    toastr[action](message, action + "!", { timeOut: 3000 });
  }

  dmacshowToast(action, message) {
    var toastr = new Toastr();
    // toastr.options.closeMethod = 'fadeOut';
    toastr.options.closeDuration = 300;
    toastr.options.progressBar = true;
    toastr.options.positionClass = "bottom right,";
    // toastr.options.closeEasing = 'swing';
    // if(action == 'success')
    toastr[action](message, action + "!", { timeOut: 5000 });
  }

  fetchVariant(domain) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'fetchVariant', { domain, organisationId: this.authService.organisationId }).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }


  async presentActionSheet(domain, request, permission) {
    let that = this;
    console.log("this.request ", request, domain)
    let data = JSON.stringify(request)
    localStorage.setItem("auditLogReq", data);
    let userID = request.userId;
    let _id = request._id;
    let userType;
    if (localStorage.getItem("userType") != null)
      userType = JSON.parse(localStorage.getItem("userType"))

    let buttons = []

    return new Promise(async (resolve, reject) => {
      let sendToSAP = {
        text: 'Send to SAP',
        icon: 'send',
        handler: () => {
          console.log('sendToSAP clicked');
          this.presentLoading("Sending to SAP, Please Wait!")
          that.sendToSap(domain, request._id).then((resp: any) => {
            // that.ionViewWillEnter();
            console.log(resp)
            this.loading.dismiss()
            resolve(resp)
            // that.presentToast(resp.message.value)
          }, error => {
            this.loading.dismiss()
            console.log(error)
          })

        }
      }

      let htsButton = {
        text: 'Auto Approve',
        icon: 'checkmark',
        handler: () => {
          console.log('Auto approve clicked');
        }
      }

      let auditButton = {
        text: 'Audit-log',
        icon: 'document-text-outline',
        handler: () => {
          console.log('audit log clicked');
          that.fetchPIMAuditLog(domain, request._id).then((resp: any) => {
            // that.ionViewWillEnter();
            console.log(resp)
            this.loading.dismiss()
            resolve(resp);
            // that.presentToast(resp.message.value)
          }, error => {
            this.loading.dismiss()
            console.log(error)
          })
        
          this.router.navigate(['/pim/pim-audit-log']);
      }
      }

      let sendToARTICLEMASTER = {
        text: 'Send to Article Master',
        icon: 'send',
        handler: () => {
          console.log('sendToARTICLEMASTER clicked');
          this.presentLoading("Sending to ARTICLEMASTER, Please Wait!")
          that.sendToArticlemaster(domain, request._id,request.variantId).then((resp: any) => {
            // that.ionViewWillEnter();
            console.log(resp)
            this.loading.dismiss()
            resolve(resp)
            // that.presentToast(resp.message.value)
          }, error => {
            this.loading.dismiss()
            console.log(error)
          })

        }
      }

      let editButton = {
        text: 'Edit',
        icon: 'pencil-outline',
        handler: () => {
          console.log('Edit clicked');
          // this.presentAlertPrompt(domain, request._id, 'Approved').then(resp => {
          //   resolve(true)
          // })
          let bankFile;
          let invoiceFile
          if (request.bankFile == '' || !request.bankFile) {
            bankFile = null
          }
          else {
            bankFile = request.bankFile;
          }
          if (request.invoiceFile == '' || !request.invoiceFile) {
            invoiceFile = null
          }
          else {
            invoiceFile = request.invoiceFile;
          }
          if (!request.usTaxDocFile || request.usTaxDocFile == '') {
            this.wForm = null
          }
          else {
            this.wForm = request.usTaxDocFile
          }
          this.router.navigate(['/supplier-portal/vendor-registration/' + request._id + '/' + bankFile + '/' + invoiceFile]);

          // this.router.navigate(['/supplier-portal/vendor-registration/' + request._id+'/'+request.bankFile+'/'+request.invoiceFile]);
        }
      }

      let edit1Button = {
        text: 'Edit',
        icon: 'pencil-outline',
        handler: () => {
          console.log('Edit clicked');
          this.router.navigate(['material/material-registration/' + request._id]);
        }
      }

      let edit2Button = {
        text: 'Edit',
        icon: 'pencil-outline',
        handler: () => {
          console.log('Edit clicked');
          this.router.navigate(['vendor/vendor-registration/' + request._id]);
        }
      }

      let edit3Button = {
        text: 'Edit',
        icon: 'pencil-outline',
        handler: () => {
          console.log('Edit clicked');
          this.router.navigate(['customer/customer-registration/' + request._id]);
        }
      }

      let edit4Button = {
        text: 'Edit',
        icon: 'pencil-outline',
        handler: () => {
          console.log('Edit clicked');
          this.router.navigate(['article/article-registration/' + request._id]);
        }
      }

      let edit5Button = {
        text: 'Edit',
        icon: 'pencil-outline',
        handler: () => {
          console.log('Edit clicked');
          this.router.navigate(['gl/gl-registration/' + request._id]);
        }
      }

      let edit6Button = {
        text: 'Edit',
        icon: 'pencil-outline',
        handler: () => {
          console.log('Edit clicked');
          this.router.navigate(['costCenter/cost-center-registration/' + request._id]);
        }
      }

      let edit7Button = {
        text: 'Edit',
        icon: 'pencil-outline',
        handler: () => {
          console.log('Edit clicked');
          this.router.navigate(['profit-center/profit-center-registration/' + request._id]);
        }
      }

      let edit8Button = {
        text: 'Edit',
        icon: 'pencil-outline',
        handler: () => {
          console.log('Edit clicked');
          this.router.navigate(['bom/bom-registration/' + request._id]);
        }
      }

      let edit9Button = {
        text: 'Edit',
        icon: 'pencil-outline',
        handler: () => {
          console.log('Edit clicked');
          this.router.navigate(['routing/routing-registration/' + request._id]);
        }
      }

      let edit10Button = {
        text: 'Edit',
        icon: 'pencil-outline',
        handler: () => {
          console.log('Edit clicked');
          let imageattachment1;
          let imageattachment2;
          let imageattachment3;
          if (request.imageattachment1 == '' || !request.imageattachment1) {
            imageattachment1 = null
          }
          else {
            imageattachment1 = request.imageattachment1;
          }
          if (request.imageattachment2 == '' || !request.imageattachment2) {
            imageattachment2 = null
          }
          else {
            imageattachment2 = request.imageattachment2;
          }
          if (request.imageattachment3 == '' || !request.imageattachment3) {
            imageattachment3 = null
          }
          else {
            imageattachment3 = request.imageattachment3;
          }
          this.router.navigate(['pim/pim-registration/' + request._id+ '/' + imageattachment1 + '/' + imageattachment2 + '/' + imageattachment3]);
        }
      }

      let edit11Button = {
        text: 'View',
        icon: 'eye-outline',
        handler: () => {
          console.log('View clicked');
          let imageattachment1;
          let imageattachment2;
          let imageattachment3;
          if (request.imageattachment1 == '' || !request.imageattachment1) {
            imageattachment1 = null
          }
          else {
            imageattachment1 = request.imageattachment1;
          }
          if (request.imageattachment2 == '' || !request.imageattachment2) {
            imageattachment2 = null
          }
          else {
            imageattachment2 = request.imageattachment2;
          }
          if (request.imageattachment3 == '' || !request.imageattachment3) {
            imageattachment3 = null
          }
          else {
            imageattachment3 = request.imageattachment3;
          }
          this.router.navigate(['pim/pim-approve/' + request._id+ '/' + imageattachment1 + '/' + imageattachment2 + '/' + imageattachment3]);
        }
      }
      let edit12Button = {
        text: 'Edit',
        icon: 'pencil-outline',
        handler: () => {
          console.log('Edit clicked');
         
          this.router.navigate(['site/site-registration/' + request._id]);
        }
      }
      let editSupplier = {
        text: 'Edit',
        icon: 'pencil-outline',
        handler: () => {
          console.log('Edit clicked');
          // this.presentAlertPrompt(domain, request._id, 'Approved').then(resp => {
          //   resolve(true)
          // })
          let bankFile;
          let invoiceFile
          if (request.bankFile == '' || !request.bankFile) {
            bankFile = null
          }
          else {
            bankFile = request.bankFile;
          }
          if (request.invoiceFile == '' || !request.invoiceFile) {
            invoiceFile = null
          }
          else {
            invoiceFile = request.invoiceFile;
          }
          if (!request.usTaxDocFile || request.usTaxDocFile == '') {
            this.wForm = null
          }
          else {
            this.wForm = request.usTaxDocFile
          }
          this.router.navigate(['/supplier-portal/vendor-update-request/' + request._id + '/' + bankFile + '/' + invoiceFile]);
          // this.router.navigate(['/supplier-portal/vendor-registration/' + request._id+'/'+request.bankFile+'/'+request.invoiceFile]);
        }
      }

      let additionalInfo = {
        text: 'Update Status',
        icon: 'information-circle-outline',
        handler: () => {
          console.log('Approve clicked');
          this.addInfoComment(domain, request._id).then(resp => {
            resolve(resp)
          })
          //   that.updateRequestStatus(domain, _id, { status: 'Approved', comment: '' }).then((resp: any) => {
          //     console.log(resp)
          //     resolve(resp)
          //   }, error => {
          //     console.log(error)
          //   })
        }
      }

      let approveButton = {
        text: 'Approve',
        icon: 'checkmark',
        handler: () => {
          console.log('Approve clicked');
          // this.presentAlertPrompt(domain, request._id, 'Approved').then(resp => {
          //   resolve(true)
          // })
          that.updateRequestStatus(domain, _id, { status: 'Approved', comment: '' }).then((resp: any) => {
            console.log(resp)
            resolve(resp)
          }, error => {
            console.log(error)
          })
        }
      }

      let denyButton = {
        text: 'Reject',
        icon: 'close',
        handler: () => {
          console.log('Reject clicked');
          this.presentAlertPrompt(domain, request._id, 'Declined').then(resp => {
            resolve(true)
          })
        }
      }

      let downloadMass = {
        text: 'Download',
        icon: 'cloud-download',
        handler: () => {
          let url = "https://api.qa.idmx.io/massdatadownload?domain=" + domain + "&requestNo=" + _id
          window.location.assign(url);
          // this.presentLoading("Sending to SAP, Please Wait!")
          // that.sendToSap(domain, request._id).then((resp: any) => {
          //   // that.ionViewWillEnter();
          //   console.log(resp)
          //   this.loading.dismiss()
          //   resolve(resp)
          //   // that.presentToast(resp.message.value)
          // }, error => {
          //   this.loading.dismiss()
          //   console.log(error)
          // })


        }
      }

      // let massInfoUrl = {
      //   Material: {
      //     url: 'massMessageReadMaterial',
      //     fields: ['ExternalMaterial', 'MaterialNumber', 'Msgtype']
      //   },
      //   Customer: {
      //     url: 'massMessageReadCustomer',
      //     fields: ['Businesspartner', 'Createdon']
      //   },
      //   Vendor: {
      //     url: 'massMessageReadVendor',
      //     fields: ['Businesspartner', 'Createdon']
      //   },
      //   Routing: {
      //     url: 'massMessageReadRouting',
      //     fields: ['MaterialNumber', 'Plant', 'Msgtype']
      //   },
      //   CostCenter: {
      //     url: 'massMessageReadCostCenter',
      //     fields: ['Kostl', 'Requestnumber']
      //   },
      //   GL: {
      //     url: 'massMessageReadGL',
      //     fields: ['MessageV1cs', 'Requestnumber','Typecs']
      //   },
      //   BOM: {
      //     url: 'massMessageReadBOM',
      //     fields: ['Bomnumber', 'Createdon']
      //   }
      // }

      // console.log("domain ", domain)


      if (request.status == 'Approved') {
        if (permission && permission.sendToSAP == true) {
          buttons.push(sendToSAP)
        }
      }

      console.log(" permission ", permission)

      if (domain == 'material' && request.status == 'Draft' && request.requestType == 'Single' && permission && permission.validator == true) {
        buttons.push(edit1Button)
      }

      if (domain == 'vendor' && request.status == 'Draft' && request.requestType == 'Single' && permission && permission.validator == true) {
        buttons.push(edit2Button)
      }

      if (domain == 'customer' && request.status == 'Draft' && request.requestType == 'Single' && permission && permission.validator == true) {
        buttons.push(edit3Button)
      }

      if (domain == 'article' && request.status == 'Draft' && request.requestType == 'Single' && permission && permission.validator == true) {
        buttons.push(edit4Button)
      }

      if (domain == 'gl' && request.status == 'Draft' && request.requestType == 'Single' && permission && permission.validator == true) {
        buttons.push(edit5Button)
      }

      if (domain == 'costCenter' && request.status == 'Draft' && request.requestType == 'Single' && permission && permission.validator == true) {
        buttons.push(edit6Button)
      }

      if (domain == 'profitCenter' && request.status == 'Draft' && request.requestType == 'Single' && permission && permission.validator == true) {
        buttons.push(edit7Button)
      }

      if (domain == 'bom' && request.status == 'Draft' && request.requestType == 'Single' && permission && permission.validator == true) {
        buttons.push(edit8Button)
      }

      if (domain == 'routing' && request.status == 'Draft' && request.requestType == 'Single' && permission && permission.validator == true) {
        buttons.push(edit9Button)
      }
      if (domain == 'site' && request.status == 'Draft' && request.requestType == 'Single' && permission && permission.validator == true) {
        buttons.push(edit12Button)
      }
      if (domain == 'pim' && request.status == 'Draft' && request.requestType == 'Single') {
        buttons.push(edit10Button)
        buttons.push(auditButton)
      }

      if (domain == 'pim' && request.status == 'New' && request.requestType == 'Single') {
        buttons.push(approveButton)
        buttons.push(denyButton)
        buttons.push(auditButton)
      }

      if (domain == 'pim' && request.status == 'Approved' && request.requestType == 'Single') {
        buttons.push(edit11Button)
        buttons.push(auditButton)
          buttons.push(sendToARTICLEMASTER)
      }

      if (domain == 'pim' && request.status == 'Declined' && request.requestType == 'Single') {
        buttons.push(auditButton)
      }

      if (request.status == 'New') {
        if (permission && permission.approveORreject == true) {
          buttons.push(approveButton)
          buttons.push(denyButton)
        }
      }

      if (request.isSupplier == false) {
        if (request.status == 'New' || request.status == 'Draft' || request.status == 'Need Info' || request.status == 'Info Updated' || request.status == 'Initiated') {
          if (domain && domain == 'supplier' && userType != "Supplier") {
            buttons.push(editButton)
            if (userType != "Supplier" && this.authService.access.Vendor.access == true && this.authService.access.Vendor.permission.approveORreject == true)
              buttons.push(additionalInfo)
          }
          if (domain && domain == 'supplier' && userType == "Supplier" && request.status != 'Info Updated') {
            buttons.push(editSupplier)
          }
        }
      }

      if (request.isSupplier == true) {
        if (request.status == 'Draft' || request.status == 'Need Info' || request.status == 'Info Updated' || request.status == 'Initiated') {
          if (domain && domain == 'supplier' && userType != "Supplier") {
            buttons.push(editButton)
            if (userType != "Supplier" && this.authService.access.Vendor.access == true && this.authService.access.Vendor.permission.approveORreject == true)
              buttons.push(additionalInfo)
          }
          if (domain && domain == 'supplier' && userType == "Supplier" && request.status != 'Info Updated') {
            buttons.push(editSupplier)
          }
        }
      }
      if (request.requestType == "Mass") {
        buttons.push(downloadMass);
      }

      // if (request.requestType == "Mass" && request.status == 'Completed') {
      //   buttons.push(infoButton);
      // }

      let cancelButton = {
        text: 'Cancel',
        // icon: 'close',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      }
      buttons.push(cancelButton)

      const actionSheet = await this.actionSheetController.create({
        header: "Request " + request.requestNo,
        cssClass: 'my-custom-class',
        mode: 'ios',
        buttons: buttons
      });
      await actionSheet.present();
    })

    // const { role, data } = await actionSheet.onDidDismiss();
    // console.log('onDidDismiss resolved with role and data', role, data);
  }

  /////////// Confirmation alert for Approve and Reject

  async presentAlertPrompt(domain, _id, status) {
    let that = this;
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: status + ' reason?',
        inputs: [
          {
            name: 'comment',
            type: 'text',
            placeholder: 'Reason'
          }
        ],
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              console.log('Confirm Cancel');
            }
          }, {
            text: 'Ok',
            handler: (data) => {
              that.updateRequestStatus(domain, _id, { status, comment: data.comment }).then((resp: any) => {
                console.log(resp)
                resolve(resp)
              }, error => {
                console.log(error)
              })
              console.log('Confirm Ok');
            }
          }
        ]
      });

      await alert.present();
    })
  }

  ///////////

  ///Added by Parul for updating Additional info
  async addInfoComment(domain, _id) {
    let that = this;
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: ' Required Additional Info',
        inputs: [
          {
            name: 'comment',
            type: 'text',
            placeholder: 'Reason'
          }
        ],
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              console.log('Confirm Cancel');
            }
          }, {
            text: 'Ok',
            handler: (data) => {
              that.updateSupplierFromBusiness(_id, data.comment).then((resp: any) => {
                console.log(resp)
                resolve(resp)
              }, error => {
                console.log(error)
              })
              console.log('Confirm Ok');
            }
          }
        ]
      });

      await alert.present();
    })
  }

  updateSupplierFromBusiness(id, comment) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'updateSupplierFromBusiness', { updatedById: this.userId, requestId: id, comment: comment }).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  ////User Access page to fetch country
  country() {
    return new Promise((resolve, reject) => {
      this.organisationId = this.authService.organisationId;
      this.http.post(this.authService.apiUrl + 'dropdownValues', { modelName: "Customer", source: "country", params: {}, organisationId: this.organisationId }).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }


  region(param) {
    return new Promise((resolve, reject) => {
      param['modelName'] = 'Customer';
      param['organisationId'] = this.authService.organisationId;
      param['source'] = 'region'
      this.http.post(this.authService.apiUrl + 'dropdownValues', param).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }


  countryCode(param) {
    return new Promise((resolve, reject) => {
      param['modelName'] = 'Vendor';
      param['organisationId'] = this.authService.organisationId;
      param['source'] = 'dialCode'
      this.http.post(this.authService.apiUrl + 'dropdownValues', param).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  groupDropdown() {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'fetchUserGroups', { 'organisationId': this.authService.organisationId }).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  deleteUserGroup(param) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'deleteUserGroups', param).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  deleteVariant(param) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'deleteVariant', param).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  deleteUser(param) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'deleteUser', param).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  /////Fetch user access

  fetchUserAccess(params) {
    this.userId = JSON.parse(localStorage.getItem('userId'))
    // let paramsObject =Object.assign(params,{_id:this.userId})
    return new Promise(async (resolve, reject) => {
      this.http.post(this.authService.url + 'fetchUserprofiledata', params).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  createUserRequest(params) {
    this.userId = JSON.parse(localStorage.getItem('userId'))
    // let paramsObject =Object.assign(params,{_id:this.userId})
    return new Promise(async (resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'registerUser', params).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  createArticleListing(params) {
    this.userId = JSON.parse(localStorage.getItem('userId'))
    // let paramsObject =Object.assign(params,{_id:this.userId})
    return new Promise(async (resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'createRequest', params).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  createUserGroup(group) {
    this.userId = JSON.parse(localStorage.getItem('userId'))
    // let paramsObject =Object.assign(params,{_id:this.userId})
    return new Promise(async (resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'createUserGroup', group).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  /////fetching mass info completed

  fetchMassInfo(url, params) {
    this.userId = JSON.parse(localStorage.getItem('userId'))
    // let paramsObject =Object.assign(params,{_id:this.userId})

    console.log("this.url ", this.authService.apiUrl)
    console.log("url ", url)

    return new Promise(async (resolve, reject) => {
      this.http.post(this.authService.url + url, params).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  /////fetch dashboard data

  fetchDashboard() {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'dashboardData', { organisationId: this.authService.organisationId }).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  //////api for Master Data Dashboard

  fetchMasterDataDashboard(timeframe: any) {
    return new Promise((resolve, reject) => {
      console.log(", organisationId: this.authService.organisationId ", this.authService.organisationId)
      this.http.post(this.authService.apiUrl + 'monthlyData', { timeFrame: timeframe, organisationId: this.authService.organisationId }).subscribe(resp => {
        this.fetchMasterData = resp;
        console.log("resfgh", this.fetchMasterData)
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  // *********************************************  DMAC START  *************************************************

  observeCurrentTable() {
    return this.currentTable.asObservable();
  }

  observeCurrentDBTable() {
    return this.currentDBTable.asObservable();
  }

  observeCurrentHanaDBTable() {
    return this.currentHanaDBTable.asObservable();
  }

  getData(): Observable<object> {
    return this.http.get(this.authService.geturl).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError('Something went wrong!');
        })
      )
  }

  postData(body): Observable<object> {
    ;
    return this.http.post(this.authService.posturl, body).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError('Something went wrong!');
        })
      )
  }

  // Upload CSV File
  dmacUploadFile(data) {
    return this.http.post(this.authService.apiUrl + 'dmacUploadFile', data);
  }

  getTables(userId,afsFlag) {
    this.userId = JSON.parse(localStorage.getItem('userId'))
    return new Promise(async (resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'tableNames', { userId: userId, afsFileType: afsFlag }).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  // Get Table Details
  getTableData(_id) {
    return this.http.post(this.authService.apiUrl + 'tableDetails', { id: _id }).toPromise()
  }

  getDBTableData(table) {
    return this.http.post(this.authService.apiUrl + 'fetchTableDataDB', { tableName: table, userId: this.userId, databaseType: "mySQL" }).toPromise()
  }

  getHanaDBTableData(table) {
    return this.http.post(this.authService.apiUrl + 'fetchTableDataDB', { tableName: table, userId: this.userId, databaseType: "hana" }).toPromise()
  }

  fetchBTPLogs(formData) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'fetchBTP-errorLogs', formData).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  fetchBTPCPILogs(formData) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'fetchMaterialCPI-new', formData).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  // Table Merge
  getTableDataMerge(_id) {
    return this.http.post(this.authService.apiUrl + 'mergeTableDetails', { id: _id }).toPromise()
  }

  getDBTableDataMerge(userId, tableNames, databaseType) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'primaryKeysDB', { userId: userId, databaseType: databaseType, tableNames: tableNames }).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  getHanaDBTableDataMerge(userId, tableNames, databaseType) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'primaryKeysDB', { userId: userId, databaseType: databaseType, tableNames: tableNames }).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  // Submit Primary Keys
  submitPKey(formData: any) {
    return this.http.post(this.authService.apiUrl + 'submitPKey', formData);
  }

  submitDBKey(formData: any) {
    return this.http.post(this.authService.apiUrl + 'fetch&mergeTablesData', formData);
  }

  applyRules(formData: any) {
    return new Promise(async (resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'tableOperation', formData).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  applyDBRules(formData: any) {
    return new Promise(async (resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'databaseTableOperation', formData).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  createConnection(formData: any) {
    return new Promise(async (resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'hanaDB', formData).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  setdataName(dataName) {
    console.log("data.....", dataName)
    this.dataName.push(dataName[0]);
    console.log("dataName", this.dataName)
  }

  getdataName() {
    return this.dataName;
  }

  setselectedPKeys(val) {
    console.log("keys.....", val)
    this.val.push(val);
    console.log("selectedPKeys", this.val)
  }

  getselectedPKeys() {
    return this.val;
  }

  downloadFile(filePath) {
    return new Promise(async (resolve, reject) => {
      this.http.get(this.authService.apiUrl + 'downloadExcel?filePath=' + filePath, { responseType: 'blob' }).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  deleteTable(postData) {
    return this.http.post(this.authService.apiUrl + 'deleteTable', postData).toPromise()
  }

  profiling(formData: any) {
    return new Promise(async (resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'profilingReport', formData).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  listTableCheck() {
    return new Promise(async (resolve, reject) => {
      this.http.get(this.authService.apiUrl + 'listTableCheck').subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  // ***************************************  DMAC END  ********************************************************


  // Get Table Details
  callApi(source, params) {
    params['organisationId'] = this.authService.organisationId;
    return new Promise(async (resolve, reject) => {
      this.http.post(this.authService.apiUrl + source, params).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  requestTypeData() {
    return this.http.post(this.authService.apiUrl + 'requestTypeData', {})
  }

  dataByRequestType(params) {
    params['organisationId'] = this.authService.organisationId;
    return this.http.post(this.authService.apiUrl + 'dataByRequestType', params)
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }


  async presentLoading(messsage) {
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: messsage,
      duration: 10000
    });
    await this.loading.present();

  }

  async dmacpresentLoading(messsage) {
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: messsage,
    });
    await this.loading.present();
  }

  loadingDismiss() {
    this.loading.dismiss();
  }

  updateMassData(param) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'updateValidatedData', param).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  sendDataToHana(connectionString, tableName, filePath) {
    return new Promise(async (resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'sendDatatoDB', {
        connectionString, tableName, filePath
      }).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  sendDataToIdmx(connectionString, tableName, sheetName, modelType) {

    let r = {
      connectionString, tableName, sheetName, databaseType: "idmx", modelType,
      userId: JSON.parse(localStorage.getItem('userId')),
      organisationId: this.authService.organisationId
    };

    console.log("sendDataToIdmx r ", r)

    return new Promise(async (resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'databaseConnection', r).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  downloadGraph() {
    let excelFileName = 'Output_File_';
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.fetchMasterData);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    console.log("file", excelBuffer, workbook, worksheet, this.fetchMasterData)
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }


  downloadSingleMassGraph(data) {
    let excelFileName = 'Output_File_';
    for (let i = 0; i < data.length; i++) {
      // data[i] = this.insertProperty(data[i], 'Exception', data[i]['Status'], 2);
      delete data[i].organisationId
      delete data[i].isBatchManagementRequired
      delete data[i].isAutoPurOrdCreationAllowed
      delete data[i].userId
      delete data[i]._id
      delete data[i].__v
      // let sku = this.data[i]['SKU'].split('-')
      // this.data[i]['SKU'] = sku[0]+'-'+sku[1];
      // this.data[i] = this.insertProperty(this.data[i], 'Article', this.data[i]['SKU'],3);
      // delete this.data[i].SKU
    }
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    console.log("file", excelBuffer, workbook, worksheet, data)
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + new Date().getTime() + EXCEL_EXTENSION);
  }

  downloadProfile(fileName) {
    console.log("filjebnjkf", fileName)
    let url = "https://api.qa.idmx.io/downloadProfilingReport?path=" + fileName//http://192.168.0.105:8182
    // let url = "http://192.168.0.105:8182/downloadProfilingReport?path="+fileName
    console.log("url", url)
    window.location.assign(url);
  }
  partnerHome(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'getPartner', data).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  inclusionHome() {
    return new Promise((resolve, reject) => {
      this.http.get(this.authService.apiUrl + 'fetchInclusionData').subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  getEccTableData(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'displayTableDetails',data).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  getCustomerVendorConData(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'fetchMergedData',data).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  sendtoS4HANA(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'updateMergedStatus',data).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  tranformToHana(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'transformDataForS4',data).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  tranformToHanaMass(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'massTransformDataForS4',data).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    }) 
  }
  updateConData(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'updateMergedRecords',data).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  createCustomerCon(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'createMergedRequestsCustomer',data).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  createCustomerSup(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'createMergedRequestsSupplier',data).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  fetchtranslateSupplierData(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'translateSupplierData',data).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  deleteInclusion(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'deleteData', data).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  sndmail(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'sendData', data).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  partnerApproved(id, name, remarks) {
    // let data = { remarks: remarks };
    return new Promise((resolve, reject) => {
      this.http.get(`https://api.qa.idmx.io/approve/${id}?name=${name}&remarks=${remarks}`).subscribe(
        resp => {
          resolve(resp);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  
  async fetchProductMaster() {

    this.userId = JSON.parse(localStorage.getItem('userId'))
    let paramsObject = Object.assign({ userId: this.userId })
    return new Promise(async (resolve, reject) => {
      console.log("this.authService.organisationId ", this.authService.organisationId)
      paramsObject.organisationId = this.authService.organisationId;
      this.http.post(this.authService.apiUrl + 'fetchProductMaster', paramsObject).subscribe(resp => {
        this.fetchRequests = resp
        resolve(resp)
      }, error => {
        reject(error)
      })
      // })


    })
  }
  dropDownData(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'dropdownValues',data).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
 

  getCockPitPer(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'fetchPercentageParameters',data).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  updateCockPitPer(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'updatePercentageParameters',data).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  getSalesTable(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'displayItemDetails',data).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  partnerFuct(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.authService.apiUrl + 'checkSalesAndPartnerData',data).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  createPrice(data) {
    return new Promise((resolve, reject) => {
      let form_data: FormData = this.jsonToFormData(data);
      this.http.post(this.authService.apiUrl + 'createRequest',form_data).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  validateAddressDmac(address) {
    return new Promise((resolve, reject) => {
      this.userId = JSON.parse(localStorage.getItem('userId'))
      this.http.post(this.authService.apiUrl + 'addressValidate1', address).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  ngOnDestroy(): void {
  }

  chatBotData(data: any) {
    return this.http.post( this.chatBotUrl + `idmx-bot` , data);
  }

}


