import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ChatBotService {
  textStream: EventEmitter<string> = new EventEmitter<string>();
  private intervalId: any;
  constructor() {}
  private streamDealy = 100; // ms
  // Simulating streaming messages
  startStreaming(message) {
    let index = 0;
    const msgArr = message.split(' ');
    this.intervalId = setInterval(() => {
      if (index < msgArr.length) {
        this.textStream.emit(msgArr[index]+ ' ');
        index++;
      } else {
        this.stopStreaming(); // Stop emitting when done
      }
    }, this.streamDealy); // Emit a message every second
  }

  stopStreaming() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }
}
