import { Component, HostListener, ViewChild, ElementRef, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from 'src/app/services/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { formatDistanceToNow } from 'date-fns';
import { ChatBotService } from 'src/app/services/chat-bot.service';
import { Subscription } from 'rxjs';
import { IonContent } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

interface ChatMessage {
  text: string;
  type: 'user' | 'bot';
  timestamp: Date;
}

@Component({
  selector: 'app-chat-bot',
  templateUrl: './chat-bot.page.html',
  styleUrls: ['./chat-bot.page.scss'],
})
export class ChatBotPage implements OnInit {
  @ViewChild(IonContent, undefined) content: IonContent;
  @ViewChild('messageInput', { static: false }) messageInput?: ElementRef;
  @ViewChild('chatContainer') private chatContainer: ElementRef;
  newMessage: string = '';
  isChatOpen: boolean = false;
  isLoading: boolean = false;
  isError: boolean = false;
  form!: FormGroup;
  showHelpCard = true;
  messages: ChatMessage[] = []; // Array to store messages
  loading = false; // Variable to track loading state
  public isStreaming: boolean = false;
  public dotVisible: boolean = false;
  public message: string = '';
  streamedContent: string = '';
  private subscription: Subscription | null = null;
  speechText: string = '';
  isListening: boolean = false; // Boolean to control the loader
  isBot: boolean = true;

  searchText = '';
  languages = [
    { name: 'Tamil', code: 'ta' },
    { name: 'French', code: 'fr' },
    { name: 'Chinese', code: 'zh' },
    { name: 'English', code: 'en' },
    { name: 'United States', code: 'en' },
    { name: 'More', code: 'more' }
  ];
  filteredLanguages = [...this.languages];

  constructor(private http: HttpClient,
    private commonService: CommonService,
    private fb: FormBuilder,
    private chatBotService: ChatBotService,
    private translate: TranslateService
  ) { translate.setDefaultLang('en'); }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  ngOnChanges() {
    this.filterLanguages();
  }

  filterLanguages() {
    this.filteredLanguages = this.languages.filter(lang =>
      lang.name.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }

  ngAfterViewInit() {
    try {
      this.scrollToBottom();
    } catch (err) {
      console.error('Could not scroll to the bottom of the chat:', err);
    }
  }
  ngOnInit() {
    this.form = this.fb.group({
      message: ['', [Validators.required, Validators.minLength(1)]]
    });
  }

  startRecording() {
    const recognition = new (window as any).webkitSpeechRecognition();
    recognition.lang = 'en-US'; // Set the language as per your need
    recognition.interimResults = false; // Get final results only
    recognition.maxAlternatives = 1;

    this.isListening = true; // Show loader when listening starts

    recognition.onresult = (event: any) => {
      const speechResult = event.results[0][0].transcript;

      this.speechText = speechResult; // Fill the input field with the recognized speech
      this.isListening = false; // Hide loader after recognition completes
      document.getElementById('myElement').click();

    };

    recognition.onerror = (event: any) => {
      console.log('Speech recognition error: ', event.error);
      this.isListening = false; // Hide loader if there's an error
    };

    recognition.onend = () => {
      this.isListening = false; // Hide loader when speech recognition ends
      document.getElementById('myElement').click();
    };

    recognition.start(); // Start the speech recognition
  }


  chatSubmit() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (!this.form.value.message) {
      return;
    }
    // if (this.form.invalid) {
    //   console.log('Form is not valid', this.form.value);
    //   return;
    // }
    const userMessage: ChatMessage = {
      text: this.form.value.message ? this.form.value.message : this.speechText,
      type: 'user',
      timestamp: new Date()
    };
    this.messages.push(userMessage);
    const formData = {
      question: this.form.value.message
    };
    this.loading = true; // Show loading indicator
    this.streamedContent = '';
    this.commonService.chatBotData(formData).subscribe(
      (res: any) => {
        // Format the bot's response before adding it to messages
        const formattedResponse = this.formatResponse(res.answer);
        const botMessage: ChatMessage = {
          text: formattedResponse,
          type: 'bot',
          timestamp: new Date()
        };
        // Start streaming the messages
        this.loading = false;
        this.subscription = this.chatBotService.textStream.subscribe((message: string) => {
          this.streamedContent += message; // Append each message
        });
        this.chatBotService.startStreaming(formattedResponse);
        this.messages.push(botMessage);
        // Hide loading indicator
      },
      (error: any) => {
        if (error) {
          this.isError = true;
        }
        this.loading = false; // Hide loading indicator
      }
    );
    this.form.reset(); // Reset the form after submission
  }

  toggleChat() {
    this.isChatOpen = !this.isChatOpen;
    this.showHelpCard = false;
    this.isBot = false
  }

  refreshChat() {
    this.messages = [];
  }

  closeChat() {
    this.isChatOpen = false
    this.isBot = true
  }

  formatResponse(response: string) {
    // Replace \n with <br>, make text between ** bold, and convert URLs into links
    let formattedResponse = response
      .replace(/###\s*(.*?):/g, '<b>$1</b>:')
      .replace(/\n/g, '<br>')
      .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
      .replace(/(https?:\/\/[^\s\)]+)/g, '<a href="$1" target="_blank">$1</a>');
    return formattedResponse;
  }

  // scrollToBottom() {
  //   if (this.chatContainer && this.chatContainer.nativeElement) {
  //     this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
  //   }

  // }

  // scr(): void {
  //   try {
  //     this.chatContainer.nativeElement.scrollTop = 0;
  //   } catch (err) {
  //     console.error('Scroll error:', err);
  //   }
  // }
  sendMessage(messageText: string) {
    this.loading = true;
    setTimeout(() => {
      this.messages.push({ text: messageText, type: 'user', timestamp: new Date() });
      this.loading = false;
    }, 500);  // Simulate a delay for loading
  }

  formatTimeAgo(timestamp: Date): string {
    return formatDistanceToNow(timestamp, { addSuffix: true });
  }
  ngAfterViewChecked() {
    this.scrollToBottom();
  }
  toggleCard() {
    this.showHelpCard = !this.showHelpCard;
  }
  scrollToBottom() {
    if (this.chatContainer?.nativeElement) {
      const chatElement = this.chatContainer.nativeElement;
      chatElement.scrollTop = chatElement.scrollHeight;
    }
  }

  scrollToTop() {
    if (this.chatContainer?.nativeElement) {
      const chatElement = this.chatContainer.nativeElement;
      chatElement.scrollTop = 0;
    }

  }
}