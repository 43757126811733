import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
// import { NgxBarcodeModule } from 'ngx-barcode';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NgxEchartsModule } from 'ngx-echarts';
import { ScrollbarThemeDirective } from './directives/scrollbar-theme.directive';
import { PathLocationStrategy, LocationStrategy } from '@angular/common';
import { NgxPrintModule } from 'ngx-print';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideAuth,getAuth } from '@angular/fire/auth';
import { provideFirestore,getFirestore } from '@angular/fire/firestore';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChatBotPage } from './components/chat-bot/chat-bot.page';
// import { NgxAutocompleteModule } from 'ngx-angular-autocomplete';


// import { FormBuilderModule } from './components/form-builder/form-builder.module';
// import { FormBuilderComponent } from './components/form-builder/form-builder.component';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { ToastNoAnimationModule } from 'ngx-toastr';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export function HttpLoaderFactory(http: HttpClient) {
  const path = './assets/i18n/';
  console.log('Translation files path:', path);
  return new TranslateHttpLoader(http, path, '.json');
}

@NgModule({
  declarations: [AppComponent, ScrollbarThemeDirective, ChatBotPage],
  entryComponents: [],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    // NgxBarcodeModule,
    NgxPrintModule,
    // BrowserAnimationsModule,
    NgxDatatableModule,
    // FormBuilderModule,
    // ToastNoAnimationModule.forRoot(),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    IonicModule.forRoot({ mode: 'ios' }),
    AppRoutingModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    // NgxAutocompleteModule
  ],
  providers: [{ provide: LocationStrategy, useClass: PathLocationStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
